import moment from "moment";
import ProductDailyOpeningListPdf from "@/components/page/product-daily-opening-list-pdf";

export default {
  name: "ProductDailiyOpening",
  components: {
    ProductDailyOpeningListPdf,
  },
  data() {
    return {
      property: {
        listElement: {
          dailyProduct: {
            currentPage: 1,
            rows: 0,
            perPage: 10,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          productName: "",
          dateFrom: "",
          dateTo: "",
        },
        animation: {
          isDownloadingFile: false,
        },
      },
      table: {
        data: {
          dailyProduct: [],
        },
      },
    };
  },

  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        productName: this.property.filterDto.productName,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/daily-open-account/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Pembukaan_Produk_Hari_Ini.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.productDailyOpeningListPdfRef.onOpenPreview({
        tableData: this.table.data.dailyProduct,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          officeName: getUserPayloadFromSession.officeName,
          filteredDate: `${this.dateToYearMonthDay(
            this.table.data.dailyProduct[0].openDate
          )} s/d ${this.dateToYearMonthDay(
            this.table.data.dailyProduct[
              this.table.data.dailyProduct.length - 1
            ].openDate
          )}  `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    async findProductDailyOpening() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.table.data.dailyProduct = [];
      this.property.listElement.dailyProduct.downloading = true;
      this.resetTable();
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/daily-open-account",
          payload: {
            productName: this.property.filterDto.productName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.table.data.dailyProduct = resp.data.data.content;
            this.property.listElement.dailyProduct.rows =
              resp.data.data.totalElements;
          }, 1000);
        } else {
          this.handleFailedGetData(resp);
        }
      } catch (error) {
        this.handleCatchError(error);
      } finally {
        setTimeout(() => {
          this.property.listElement.dailyProduct.downloading = false;
        }, 1000);
      }
    },
    resetTable() {
      this.table.data.dailyProduct = [];
      this.property.listElement.dailyProduct.rows = 0;
      this.property.listElement.dailyProduct.currentPage = 1;
      this.property.listElement.message = "";
      this.property.listElement.dailyProduct.perPage = 10;
    },
    async changePaginationDailyProduct(event) {
      this.table.data.dailyProduct = [];
      this.property.listElement.dailyProduct.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/daily-open-account",
          payload: {
            productName: this.property.filterDto.productName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.dailyProduct.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.dailyProduct = resp.data.data.content;
            this.property.listElement.dailyProduct.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetData(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchError(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.dailyProduct.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetData(resp) {
      console.log(resp);
      this.table.data.dailyProduct = [];
      this.property.listElement.dailyProduct.rows = 0;
      this.property.listElement.dailyProduct.message = resp.data.message;
    },
    handleCatchError(error) {
      console.log(error.response);
      this.table.data.dailyProduct = [];
      this.property.listElement.dailyProduct.rows = 0;
      this.property.listElement.dailyProduct.currentPage = 1;
      this.property.listElement.dailyProduct.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    insertDefaultValue() {
      this.property.filterDto.dateFrom = this.dateToYearMonthDay(new Date());
      this.property.filterDto.dateTo = this.dateToYearMonthDay(new Date());
    },
  },
  mounted() {
    this.insertDefaultValue();
    this.findProductDailyOpening();
  },
};
