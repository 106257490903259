<template>
  <div>
    <b-modal
      :on-cancel="closePreview"
      v-model="showModal"
      :width="1000"
      scroll="clip"
    >
      <div class="modal-card h-screen" style="width: auto;">
        <header class="modal-card-head">
          <span class="text-base">Preview</span>
        </header>
        <div id="output-canvas" class="modal-card-body w-full p-3">
          <div class="flex justify-center w-full items-center flex-col p-0">
            <div class="w-full flex justify-between items-start flex-row">
              <span class="product-daily-opening-print-out-font font-bold"
                >HIK PARAHYANGAN</span
              >
              <span class="product-daily-opening-print-out-font font-bold"
                >Tanggal :
                {{
                  printOutData ? printOutData.letterhead.printDate : "-"
                }}</span
              >
            </div>
            <div class="w-full mt-1 flex justify-between items-start flex-row">
              <span class="product-daily-opening-print-out-font font-bold">{{
                printOutData ? printOutData.letterhead.officeName : "-"
              }}</span>
              <span class="font-bold"></span>
            </div>
            <div class="w-full mt-1 flex justify-center items-center flex-col">
              <span class="product-daily-opening-print-out-font font-bold"
                >PEMBUKAAN PRODUK</span
              >
              <span class="product-daily-opening-print-out-font font-bold mt-1"
                >PERIODE :
                {{
                  printOutData ? printOutData.letterhead.filteredDate : ""
                }}</span
              >
            </div>
            <b-table
              class="mt-3"
              narrowed
              v-if="printOutData"
              :data="printOutData.tableData"
            >
              <template v-slot:empty>
                <div class="flex justify-center items-center p-3">
                  <span class="product-daily-opening-print-out-font">
                    No Data Found
                  </span>
                </div>
              </template>
              <b-table-column centered field="noUrut" label="No">
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.index + 1
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="officeName" label="Cabang">
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.officeName
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="accountNumber"
                label="No. Rekening"
              >
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.accountNumber
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="cifIdName" label="Nama">
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.cifIdName
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="productName" label="Produk">
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.productName ? props.row.productName : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="openDate" label="Tgl Buka">
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.openDate
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="createByUserCode"
                label="User Entry"
              >
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.createByUserCode
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="auhtorizedByUserCode"
                label="User Otorisasi"
              >
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.auhtorizedByUserCode
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="qqName" label="Nama QQ">
                <template v-slot:header="{ column }">
                  <span class="product-daily-opening-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="product-daily-opening-print-out-font">{{
                    props.row.qqName
                  }}</span>
                </template>
              </b-table-column>
            </b-table>
            <div
              class="mt-20 ml-4 flex justify-start align-start w-full flex-col"
            >
              <div>
                <span
                  class="font-bold product-daily-opening-print-out-font mr-2"
                >
                  ( Maker )
                </span>
                <span
                  class="font-bold product-daily-opening-print-out-font mr-2"
                >
                  ( Checker )
                </span>
                <span class="font-bold product-daily-opening-print-out-font">
                  ( Signer )
                </span>
              </div>
              <div>
                <span class="font-bold product-daily-opening-print-out-font">
                  Print By : {{ printOutData ? printOutData.printBy : "-" }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button @click="closePreview" label="Batal" />
          <b-button
            :loading="isLoading"
            :disabled="isLoading"
            @click="onPressPrint"
            label="Print Out"
            type="is-link"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "ProductDailyOpeningListPdf",
  data() {
    return {
      showModal: false,
      isLoading: false,
      printOutData: null,
    };
  },

  methods: {
    async closePreview() {
      this.showModal = false;
      await this.simpleWait(1000);
      this.printOutData = null;
    },
    onOpenPreview(payload) {
      this.showModal = true;
      this.printOutData = payload;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        margin: 0.1,
        filename: `Rekap_Pembukaan_Produk.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      // New Promise-based usage:
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>

<style>
.product-daily-opening-print-out-font {
  font-size: 8px;
}
</style>
